import React, { useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from 'components/ui-components-v2/Accordion';
import AccordionSummary from 'components/ui-components-v2/AccordionSummary';
import AccordionDetails from 'components/ui-components-v2/AccordionDetails';
import { MultiLevelCheckboxList } from 'components/input/MultiLevelCheckboxList';
import { ChecklistOptionKey, Value } from 'components/input/MultiLevelCheckboxList/components';
import BrickHelpers from 'components/bricks/helpers/brick.helpers';
import { PlacementsSection } from 'components/bricks/types/placement.type';
import Translation from 'components/data/Translation';
import { Channels } from 'components/bricks/types/channels';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import useBrick from 'components/bricks/hooks/useBrick';
import Typography from 'components/ui-components-v2/Typography';
import GenericIcon, { GenericSvgIcon } from 'components/ui-components/GenericIcon';

import './../styles/main.scss';

interface PreviewData {
    description?: string;
    image?: string;
}

const PlacementsBlock = () => {
    const { brick } = useBrick();

    // Placement data
    const placements: ChecklistOptionKey[] = useMemo(() => {
        if (!brick) return [];

        // Platform of placements
        const placementSections = BrickHelpers.getBrickData<PlacementsSection[]>(brick.subType, 'placements');

        // Brick channels
        const brickChannels = BrickHelpers.getBrickData<Channels>(brick.subType, 'channels');

        return placementSections?.map((placementSection) => {
            const sectionPlacements = placementSection.children;
            // Keys of channels
            const placementChannelKeys = [...new Set(sectionPlacements.map((placement) => placement.channel))];

            // Gets channel items
            const placementChannelsForType: ChecklistOptionKey[] = placementChannelKeys.map((placementChannelKey) => {
                // Filters all the placements to match current type and channel
                const placements = sectionPlacements.filter((placement) => placement.channel === placementChannelKey);

                // Returns placement item
                const placementItems: ChecklistOptionKey[] = placements.map((placement) => {
                    return {
                        type: 'item',
                        key: placement.key,
                        label: placement.label,
                        icon: placement.icon || 'image',
                        description: placement.description || placements[0].description,
                        previewImage: placement.previewImage || placements[0].previewImage
                    };
                });

                // Returns channel item
                return {
                    type: 'item',
                    key: placementChannelKey,
                    label: brickChannels?.[placementChannelKey],
                    icon: placementChannelKey,
                    children: placementItems
                };
            });

            // Returns type item
            return {
                type: 'section',
                key: placementSection.key,
                label: placementSection.label,
                children: placementChannelsForType
            };
        });
    }, [brick]);

    const firstPlacement = placements?.[0]?.children?.[0]?.children?.[0];
    const [previewData, setPreviewData] = useState<PreviewData>({
        description: firstPlacement?.description || '',
        image: firstPlacement?.previewImage || ''
    });

    const [selectedPlacements, setSelectedPlacements] = useState<string[]>(brick?.data?.settings?.targeting?.placements || []);

    const handleOnChange = (checkedItems: string[]) => {
        if (!brick) return;

        setSelectedPlacements(checkedItems);

        BricksComponentStoreHelper.setBrickModel(brick.id, 'data.settings.targeting.placements', checkedItems);
    };

    /**
     * Handles hover events on placement items, updating the preview description and preview image
     *
     * @param {string} [hoveredItem] - The key of the hovered placement item.
     *
     * @returns {void}
     */
    const handleHover = (hoveredItem?: string) => {
        if (!hoveredItem)
            return setPreviewData({
                description: '',
                image: ''
            });

        const hoveredPlacementObj = placements
            .flatMap((section) => section.children)
            .flatMap((channel) => channel.children)
            .find((placement) => placement.key === hoveredItem);

        setPreviewData({
            description: hoveredPlacementObj?.description || '',
            image: hoveredPlacementObj?.previewImage || null
        });
    };

    if (!brick) return null;

    return (
        <div className="placements-block">
            <Accordion className="placements-block__accordion--root" disableGutters defaultExpanded>
                <AccordionSummary className="placements-block__accordion__summary--root" expandIcon={<ExpandMoreIcon />}>
                    {Translation.get('placementsBlock.title', 'bricks')}
                </AccordionSummary>
                <AccordionDetails className="placements-block__accordion">
                    <MultiLevelCheckboxList
                        identifier="key"
                        selectOnlyChildren={true}
                        onMutation={(value: Value) => handleOnChange(value.checkedItems)}
                        onMouseHoverItem={handleHover}
                        value={{ checkedItems: selectedPlacements ? selectedPlacements : [] }}
                        items={placements}
                    />
                    <div className="placements-block__accordion__preview-section">
                        <div className="placements-block__accordion__preview-section__image">
                            {previewData.image && <img src={previewData.image} alt="Preview" />}
                        </div>
                        {previewData.description && (
                            <div className="placements-block__accordion__preview-section__info">
                                <GenericIcon icon={'info' as GenericSvgIcon} />
                                <Typography className="placements-block__accordion__preview-section__info__text">{previewData.description}</Typography>
                                <a
                                    href="https://www.facebook.com/business/ads-guide/update/image"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="placements-block__accordion__preview-section__info__more">
                                    More info
                                </a>
                            </div>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default PlacementsBlock;
