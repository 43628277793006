import { Channel } from 'components/bricks/data/allChannels';

const allPresetChannels: Channel[] = [
    { type: 'all', title: 'All presets', description: 'All available presets', icon: 'apps' },
    { type: 'social', title: 'Social', description: 'Presets for social media', icon: 'share' },
    { type: 'display', title: 'Display', description: 'Presets for display advertising', icon: 'space_dashboard' },
    { type: 'video', title: 'Video', description: 'Presets for video advertising', icon: 'movie' }
];

export default allPresetChannels;
