import React, { useMemo } from 'react';
import Translation from 'components/data/Translation';
import SettingsPreviewData from './interfaces/settings-preview-data';

import './styles/settings-preview.scss';

interface Props {
    data?: SettingsPreviewData;
}

/**
 * Responsible for showing a summary of key and value pairs coming from the bricks settings.
 */
const SettingsPreview: React.FC<Props> = ({ data }) => {
    if (!data) return;

    const keys = useMemo(() => Object.keys(data), [data]);

    return (
        <div className={'settings-preview'}>
            <div className="settings-preview__header">{Translation.get('summary', 'bricks')}</div>
            {keys.map((key) => {
                return (
                    <div key={key} className={'settings-preview__item'}>
                        <div className={'settings-preview__item__title'}>{key}</div>
                        <div className={'settings-preview__item__value'}>{data[key]}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default SettingsPreview;
