import React from 'react';
import './main.scss';
import classNames from 'classnames';

type Props = {
    children: any; // The React elements that this component wraps
    classes?: {
        root?: string;
    };
    direction?: 'row' | 'column';
    fullWidth?: boolean;
};

/**
 * This component is used to wrap the content of a tab in the detailview of a brick. It adds some padding by default, but
 * the main functionality is to divide it's children into columns automatically. If no width is set in the children, it
 * divides it equally by the amount of children
 */
const TabWrapper = ({ children, classes, direction = 'column', fullWidth = true }: Props): React.ReactElement => {
    if (!Array.isArray(children)) {
        return <div className={classNames('bricks-tab-wrapper__single', classes?.root)}>{children}</div>;
    }
    return (
        <div
            className={classNames('bricks-tab-wrapper', classes?.root, {
                'bricks-tab-wrapper--row': direction === 'row',
                'bricks-tab-wrapper--column': direction === 'column'
            })}>
            {children?.length > 1 &&
                children.map((child, index) => {
                    const tabWidth: number = child?.props?.width || 100 / children.length;

                    return (
                        <div key={index} className="bricks-tab-wrapper__column" style={{ width: fullWidth ? '100%' : `${tabWidth}%` }}>
                            {child}
                        </div>
                    );
                })}
        </div>
    );

    // if (!Array.isArray(children)) {
    //     return (
    //         <div className="bricks-tab-wrapper__single" style={style}>
    //             {children}
    //         </div>
    //     );
    // }

    // return (
    //     <div className="bricks-tab-wrapper__multiple" style={style}>
    //         {children?.length > 1 &&
    //             children.map((child, index) => {
    //                 const tabWidth: number = child?.props?.width || 100 / children.length;

    //                 return (
    //                     <div key={index} className="bricks-tab-wrapper__column" style={{ width: fullWidth ? '100%' : `${tabWidth}%` }}>
    //                         {child}
    //                     </div>
    //                 );
    //             })}
    //     </div>
    // );
};

export default TabWrapper;
