import { RestrictionObjects } from 'components/bricks/data/restrictions';
import { BrickSocialPlatform } from 'components/bricks/types/brick.type';
import BricksStateHelpers from 'components/bricks/helpers/bricks-state.helpers';
import Resources from 'components/data/Resources/components';
import { generateKey } from 'components/template-designer/utils/generateKey';
import Translation from 'components/data/Translation';
import { Preset, CustomPresetsData } from '../../../../../types/preset';

class PresetHelpers {
    /**
     * Create filter options based on restriction values from combined presets.
     * @param combinedPresets The array of combined presets
     * @param restrictionKey The key of the restriction (e.g., 'minWidth', 'minHeight')
     * @param label The label for the filter
     * @param type The type of filter input (e.g., 'selectMultiple')
     * @returns The filter setup object for the specified restriction
     */
    static getFilterForRestriction(
        combinedPresets: Preset[],
        restrictionKey: string,
        label: string,
        type: string
    ): {
        label: string;
        name: string;
        type: string;
        options: { key: string; value: string }[];
    } {
        const uniqueValues = [...new Set(combinedPresets.map((preset) => preset.restrictions?.[restrictionKey]).filter(Boolean))];
        const unit = RestrictionObjects[restrictionKey]?.unit || '';
        const options = uniqueValues.map((value) => ({
            key: value.toString(),
            value: value.toString() + unit
        }));

        return {
            label,
            name: restrictionKey,
            type,
            options
        };
    }

    /**
     * Modify platform filter options based on the selected channel.
     * @param combinedPresets The array of combined presets
     * @param selectedChannel The selected channel to filter by
     * @param filterSetup The initial filter setup array
     * @returns Updated filter setup with modified platform options
     */
    static modifyPlatformFiltersBasedOnChannel(combinedPresets: Preset[], selectedChannel: string, filterSetup: any): any {
        const filterSetupCopy = [...filterSetup];
        const presetsForChannel = combinedPresets.filter((preset) => preset.channel === selectedChannel || selectedChannel === 'all');
        const uniquePlatforms = [...new Set(presetsForChannel.map((preset) => preset.platform).filter(Boolean))] as BrickSocialPlatform[];

        const platformFilterSetup = filterSetupCopy.find((filter) => filter.name === 'platform');
        if (platformFilterSetup) {
            platformFilterSetup.options = uniquePlatforms.map((platform) => ({
                key: platform,
                value: platform.charAt(0).toUpperCase() + platform.slice(1)
            }));
        }

        return filterSetupCopy;
    }

    /**
     * Saves or updates a custom preset, syncing it with resources
     * If the title is empty or contains "unknown," a fallback title is generated
     * from asset type, file format, width, and height
     *
     * @param {Preset} newPreset - The custom preset to save or update.
     * @param {Preset[]} currentCustomPresets - List of existing custom presets.
     * @returns {Promise<Preset[]>} - Updated list of custom presets.
     */
    static async saveCustomPreset(newPreset: Preset, currentCustomPresets: Preset[]): Promise<Preset[]> {
        // Generate fallback title if the title is not provided
        if (newPreset.title === '' || newPreset.title.toLowerCase().includes('unknown')) {
            const { assetType, restrictions } = newPreset;
            const fileFormat = newPreset.fileExtension || Translation.get('customPresetDialog.unknownFormat', 'bricks');
            const width = restrictions?.width || Translation.get('customPresetDialog.unknownWidth', 'bricks');
            const height = restrictions?.height || Translation.get('customPresetDialog.unknownHeight', 'bricks');

            newPreset.title = `${assetType} (${fileFormat}) - ${width} x ${height}`;
        }

        // Check if editing an existing preset
        const existingIndex = currentCustomPresets.findIndex((p) => p.identifier === newPreset.identifier);

        // Update or add based on identifier match
        let updatedCustomPresets;
        if (existingIndex !== -1) {
            // If it's an existing preset, replace it with the updated one
            updatedCustomPresets = [...currentCustomPresets];
            updatedCustomPresets[existingIndex] = newPreset;
        } else {
            // For new presets, generate an identifier if missing
            newPreset.identifier = newPreset.identifier || generateKey();
            updatedCustomPresets = [...currentCustomPresets, newPreset];
        }

        const customPresetsData: CustomPresetsData = { presets: { add: updatedCustomPresets, removeKeysOfPresets: [] } };

        await BricksStateHelpers.setCustomPresetsState('presets', customPresetsData.presets);
        Resources.set('custom_presets', customPresetsData);

        return updatedCustomPresets;
    }

    /**
     * Delete a custom preset, update the custom presets list, and sync with resources and backend.
     * @param presetToDelete The preset to delete
     * @param currentCustomPresets The existing list of custom presets
     * @returns Updated custom presets list with the specified preset removed
     */
    static async deleteCustomPreset(presetToDelete: Preset, currentCustomPresets: Preset[]): Promise<Preset[]> {
        const updatedCustomPresets = currentCustomPresets.filter((preset) => preset.identifier !== presetToDelete.identifier);

        const customPresetsData: CustomPresetsData = {
            presets: { add: updatedCustomPresets, removeKeysOfPresets: [] }
        };

        await BricksStateHelpers.setCustomPresetsState('presets', customPresetsData.presets);
        Resources.set('custom_presets', customPresetsData);

        return updatedCustomPresets;
    }
}

export default PresetHelpers;
