import { Preset } from '../../../../../types/preset';

/**
 * format the asset type of a preset object
 * @param assetType the asset type of a preset object
 * @returns the formatted asset type
 */
export const getAssetTypeName = (assetType: Preset['assetType']) => {
    if (Array.isArray(assetType)) {
        return assetType.map((type) => type).join(', ');
    }

    return assetType;
};

/**
 * format the file type of a preset object
 * @param fileExtension the file type of a preset object
 * @returns the formatted file type
 */
export const getFileExtensionName = (fileExtension: Preset['fileExtension']) => {
    if (Array.isArray(fileExtension)) {
        return fileExtension.map((type) => type).join(', ');
    }

    return fileExtension;
};

/**
 * Convert a time in seconds to a human-readable format.
 * @param seconds - The time in seconds.
 * @returns {string} - The formatted time string.
 */
export const formatSecondsToHumanReadable = (seconds: number): string => {
    const secondsInMinute = 60;
    const secondsInHour = 3600;

    // If less than a minute, return seconds only
    if (seconds < secondsInMinute) {
        return `${seconds} sec`;
    } else if (seconds < secondsInHour) {
        // If less than an hour, calculate minutes and remaining seconds
        const minutes = Math.floor(seconds / secondsInMinute);
        const remainingSeconds = seconds % secondsInMinute;
        const result: string[] = [];
        // Add minutes to the result if greater than 0
        if (minutes > 0) result.push(`${minutes} min`);
        // Add remaining seconds to the result if greater than 0
        if (remainingSeconds > 0) result.push(`${remainingSeconds} sec`);
        // Join the result with ' and ' if there are both minutes and seconds, otherwise join with ''
        return result.join(result.length > 1 ? ' and ' : '');
    } else {
        // If an hour or more, calculate hours, remaining minutes, and remaining seconds
        const hours = Math.floor(seconds / secondsInHour);
        const remainingMinutes = Math.floor((seconds % secondsInHour) / secondsInMinute);
        const remainingSeconds = seconds % secondsInMinute;
        const result: string[] = [];
        // Add hours to the result, with proper pluralization
        if (hours > 0) result.push(`${hours} hour${hours > 1 ? 's' : ''}`);
        // Add remaining minutes to the result if greater than 0
        if (remainingMinutes > 0) result.push(`${remainingMinutes} min`);
        // Add remaining seconds to the result if greater than 0
        if (remainingSeconds > 0) result.push(`${remainingSeconds} sec`);
        // Join the result with ', ' and ' and ' for the last component if there are multiple components
        return result.length > 1 ? result.slice(0, -1).join(', ') + ' and ' + result.slice(-1) : result.join('');
    }
};
