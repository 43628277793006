import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { fileTypeExtraFields } from '../data/custom-presets';

const customPresetDialogSettings: IEditorBlock[] = [
    {
        title: 'Title & Description',
        itemType: 'block',
        type: 'multiInput',
        opened: false,
        items: [
            {
                type: 'text',
                model: 'title',
                label: 'Title',
                fullWidth: true,
                useValueObject: false
            },
            {
                type: 'text',
                model: 'description',
                label: 'Description',
                fullWidth: true,
                multiline: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Media Settings',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'assetType',
                label: 'File Type',
                fullWidth: true,
                options: [
                    { key: 'image', value: 'Image' },
                    { key: 'video', value: 'Video' },
                    { key: 'audio', value: 'Audio' },
                    { key: 'document', value: 'Document' },
                    { key: 'zip', value: 'Web Assets' }
                ],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'image'`,
                options: ['jpg', 'jpeg', 'png', 'gif'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'video'`,
                options: ['mp4', 'avi', 'mov', 'mkv'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'audio'`,
                options: ['mp3', 'wav', 'flac'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'document'`,
                options: ['pdf', 'docx', 'xlsx', 'pptx'],
                useValueObject: false
            },
            {
                type: 'select',
                model: 'fileExtension',
                label: 'File Format',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'zip'`,
                options: ['zip'],
                useValueObject: false
            },
            {
                type: 'number',
                model: 'restrictions.width',
                label: 'Width',
                maxLength: 4,
                fullWidth: true,
                useValueObject: false,
                itemType: 'input'
            },
            {
                type: 'number',
                model: 'restrictions.height',
                label: 'Height',
                fullWidth: true,
                maxLength: 4,
                useValueObject: false,
                itemType: 'input'
            }
        ]
    },
    // Extra fields block
    {
        title: 'Extra fields',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'autocomplete',
                model: 'extraFields.image',
                label: 'Extra Fields',
                fullWidth: true,
                multiple: true,
                condition: `additionalVars.customPreset.assetType === 'image'`,
                options: fileTypeExtraFields.image,
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'extraFields.video',
                label: 'Extra Fields',
                fullWidth: true,
                multiple: true,
                condition: `additionalVars.customPreset.assetType === 'video'`,
                options: fileTypeExtraFields.video,
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'extraFields.audio',
                label: 'Extra Fields',
                fullWidth: true,
                multiple: true,
                condition: `additionalVars.customPreset.assetType === 'audio'`,
                options: fileTypeExtraFields.audio,
                useValueObject: false
            },
            {
                type: 'autocomplete',
                model: 'extraFields.document',
                label: 'Extra Fields',
                fullWidth: true,
                multiple: true,
                condition: `additionalVars.customPreset.assetType === 'document'`,
                options: fileTypeExtraFields.document,
                useValueObject: false
            },
            // Image-specific fields
            {
                type: 'select',
                model: 'recommendations.colorMode',
                label: 'Color Mode',
                fullWidth: true,
                options: ['RGB', 'CMYK'],
                condition: `additionalVars.customPreset.assetType === 'image' && additionalVars.customPreset?.extraFields.image.some(field => field === 'colorMode')`,
                useValueObject: false
            },
            {
                type: 'text',
                model: 'recommendations.aspectRatio',
                label: 'Aspect Ratio',
                fullWidth: true,
                condition: `(additionalVars.customPreset.assetType === 'image' || additionalVars.customPreset.assetType === 'video') &&
                ((additionalVars.customPreset.extraFields?.image?.includes('aspectRatio')) ||
                (additionalVars.customPreset.extraFields?.video?.includes('aspectRatio')))`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'restrictions.minWidth',
                label: 'Minimum Width',
                fullWidth: true,
                condition: `(additionalVars.customPreset.assetType === 'image' || additionalVars.customPreset.assetType === 'video') &&
                ((additionalVars.customPreset.extraFields?.image?.includes('minWidth')) ||
                (additionalVars.customPreset.extraFields?.video?.includes('minWidth')))`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'restrictions.maxWidth',
                label: 'Maximum Width',
                fullWidth: true,
                condition: `(additionalVars.customPreset.assetType === 'image' || additionalVars.customPreset.assetType === 'video') &&
                            ((additionalVars.customPreset.extraFields?.image?.includes('maxWidth')) ||
                             (additionalVars.customPreset.extraFields?.video?.includes('maxWidth')) )`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'restrictions.minHeight',
                label: 'Minimum Height',
                fullWidth: true,
                condition: `(additionalVars.customPreset.assetType === 'image' || additionalVars.customPreset.assetType === 'video') &&
                            ((additionalVars.customPreset.extraFields?.image?.includes('minHeight')) ||
                             (additionalVars.customPreset.extraFields?.video?.includes('minHeight')) )`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'restrictions.maxHeight',
                label: 'Maximum Height',
                fullWidth: true,
                condition: `(additionalVars.customPreset.assetType === 'image' || additionalVars.customPreset.assetType === 'video') &&
                            ((additionalVars.customPreset.extraFields?.image?.includes('maxHeight')) ||
                             (additionalVars.customPreset.extraFields?.video?.includes('maxHeight')) )`,
                useValueObject: false
            },

            // Video-specific fields
            {
                type: 'number',
                model: 'recommendations.maxFileSize',
                label: 'Maximum File Size',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'video' && 
                            additionalVars.customPreset.extraFields.video?.some(field => field === 'maxFileSize')`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'recommendations.frameRate',
                label: 'Frame Rate (fps)',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'video' && additionalVars.customPreset.extraFields.video.some(field => field === 'frameRate')`,
                useValueObject: false
            },
            {
                type: 'text',
                model: 'recommendations.codec',
                label: 'Codec',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'video' && additionalVars.customPreset.extraFields.video.some(field => field === 'codec')`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'recommendations.bitrate',
                label: 'Bitrate (kbps)',
                fullWidth: true,
                condition: `(additionalVars.customPreset.assetType === 'video' || additionalVars.customPreset.assetType === 'audio') &&
                ((additionalVars.customPreset.extraFields?.video?.includes('bitrate')) ||
                 (additionalVars.customPreset.extraFields?.audio?.includes('bitrate')))`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'recommendations.minDuration',
                label: 'Minimum Duration (seconds)',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'video' && additionalVars.customPreset.extraFields.video.some(field => field === 'minDuration')`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'recommendations.maxDuration',
                label: 'Maximum Duration (seconds)',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'video' && additionalVars.customPreset.extraFields.video.some(field => field === 'maxDuration')`,
                useValueObject: false
            },

            // Audio-specific fields
            {
                type: 'number',
                model: 'recommendations.sampleRate',
                label: 'Sample Rate (kHz)',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'audio' && additionalVars.customPreset.extraFields.audio.some(field => field === 'sampleRate')`,
                useValueObject: false
            },
            {
                type: 'select',
                model: 'channels',
                label: 'Channels',
                fullWidth: true,
                options: ['Mono', 'Stereo', '5.1 Surround'],
                condition: `additionalVars.customPreset.assetType === 'audio' && additionalVars.customPreset.extraFields.audio.some(field => field === 'channels')`,
                useValueObject: false
            },

            // Document-specific fields
            {
                type: 'number',
                model: 'recommendations.numberOfPages',
                label: 'Number of Pages',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'document' && additionalVars.customPreset.extraFields.document.some(field => field === 'numberOfPages')`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'recommendations.pageWidth',
                label: 'Page Width (mm)',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'document' && additionalVars.customPreset.extraFields.document.some(field => field === 'pageWidth')`,
                useValueObject: false
            },
            {
                type: 'number',
                model: 'recommendations.pageHeight',
                label: 'Page Height (mm)',
                fullWidth: true,
                condition: `additionalVars.customPreset.assetType === 'document' && additionalVars.customPreset.extraFields.document.some(field => field === 'pageHeight')`,
                useValueObject: false
            }
        ]
    }
];

export default customPresetDialogSettings;
