const filterSetup = [
    {
        label: 'Platform',
        name: 'platform',
        type: 'selectMultiple',
        options: [
            { key: 'meta', value: 'Meta' },
            { key: 'youtube', value: 'Youtube' },
            { key: 'multi', value: 'Multi' }
        ]
    },
    {
        label: 'Asset type',
        name: 'assetType',
        type: 'selectMultiple',
        options: [
            { key: 'image', value: 'Image' },
            { key: 'video', value: 'Video' },
            { key: 'zip', value: 'Zip' }
        ]
    },
    {
        label: 'File extension',
        name: 'fileExtension',
        type: 'selectMultiple',
        options: [
            { key: 'zip', value: 'zip' },
            { key: 'jpg', value: 'jpg' },
            { key: 'png', value: 'png' },
            { key: 'mp4', value: 'mp4' },
            { key: 'mov', value: 'mov' },
            { key: 'gif', value: 'gif' },
            { key: 'wmv', value: 'wmv' },
            { key: 'avi', value: 'avi' },
            { key: 'flv', value: 'flv' },
            { key: 'mpeg', value: 'mpeg' },
            { key: 'mp4', value: 'mp4' },
            { key: '3gp', value: '3gp' },
            { key: 'webm', value: 'webm' },
            { key: 'hevc', value: 'hevc' }
        ]
    }
];

export default filterSetup;
