import { BrickChannel, FileExt } from 'components/bricks/types/brick.type';
import { Preset } from 'components/bricks/types/preset';
import { filterItemsByChannel, filterItemsBySearchTerm } from 'components/bricks/helpers/brick-filters.helpers';
import { PresetFilters } from '../types/PresetInfo.type';

const filterPresets = (presets: Preset[], filters: PresetFilters, activeChannel: BrickChannel): Preset[] => {
    let filteredPresets = [...presets];
    filteredPresets = filterItemsByChannel(filteredPresets, activeChannel);

    if (filters.searchTerm) filteredPresets = filterItemsBySearchTerm(filteredPresets, filters.searchTerm, ['title', 'description, fileType']);
    if (filters.assetType) filteredPresets = filterPresetsByAssetType(filteredPresets, filters.assetType);
    if (filters.platform) filteredPresets = filterPresetsByPlatform(filteredPresets, filters.platform);
    if (filters.fileExtension) filteredPresets = filterPresetsByFileExtension(filteredPresets, filters.fileExtension);
    if (filters.minWidth) filteredPresets = filterPresetsByRestriction(filteredPresets, filters.minWidth, 'minWidth');
    if (filters.minHeight) filteredPresets = filterPresetsByRestriction(filteredPresets, filters.minHeight, 'minHeight');
    if (filters.minAspectRatio) filteredPresets = filterPresetsByRestriction(filteredPresets, filters.minAspectRatio, 'minAspectRatio');
    if (filters.maxAspectRatio) filteredPresets = filterPresetsByRestriction(filteredPresets, filters.maxAspectRatio, 'maxAspectRatio');

    return filteredPresets;
};

/**
 * Filters presets by asset type
 * @param presets
 * @param assetTypes
 * @returns filters presets
 */
const filterPresetsByAssetType = (presets: Preset[], assetTypes: string[]): Preset[] => {
    return presets.filter((preset) => assetTypes.includes(preset.assetType));
};

/**
 * Filters presets by platform
 * @param presets
 * @param platforms
 * @returns Filtered presets
 */
const filterPresetsByPlatform = (presets: Preset[], platforms: string[]): Preset[] => {
    return presets.filter((preset) => platforms.includes(preset.platform || ''));
};

/**
 * Filters presets by file extension
 * @param presets
 * @param fileExtension
 * @returns filtered presets
 */
const filterPresetsByFileExtension = (presets: Preset[], fileExtension: FileExt[]): Preset[] => {
    return presets.filter((preset) => fileExtension.some((fileExtension) => preset.fileExtension.includes(fileExtension)));
};

/**
 * Filters preset by restriction
 * @param presets
 * @param filters
 * @param restriction key
 * @returns filtered presets
 */
const filterPresetsByRestriction = (presets: Preset[], filters: string[], restriction: string): Preset[] => {
    return presets.filter((preset) =>
        preset.restrictions && preset.restrictions[restriction] ? filters.includes(preset.restrictions[restriction].toString()) : false
    );
};

export { filterPresets };
